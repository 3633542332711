<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-title>Zwischenbericht</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Fach
                  </th>
                  <th class="text-left">
                    Note Quartal
                  </th>
                  <th class="text-left">
                    Prognose
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in exams"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.mark1 }}</td>
                  <td>{{ item.mark2 }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  export default {

    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'SemesterMarks',
    },
    data () {
      return {
        exams: [
          {
            name: 'Administration & Handel',
            mark1: 6.0,
            mark2: 6.0,
            mark3: 6.0,
            mark4: 6.0,
          },
          {
            name: 'Administration',
            mark1: 6.0,
            mark2: 5.5,
            mark3: 6.0,
            mark4: 6.0,
          },
          {
            name: 'Wirtschaft und Gesellschaft',
            mark1: 6.0,
            mark2: 5.5,
            mark3: 6.0,
            mark4: 6.0,
          },
        ],
      }
    },
  }
</script>
